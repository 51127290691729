body {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  line-height: 1.4;
  color: $color-gray-dark-2;
  box-sizing: border-box;
  position: relative;
  background: linear-gradient(
    $color-white 0 4.5rem,
    $color-gray-light-4 4.5rem,
    $color-gray-light-1 6rem,
    $color-gray-light-1 6rem 100%
  );
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  color: $color-black;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.heading {
  &-1 {
    font-size: $heading-1;
  }
  &-2 {
    font-size: $heading-2;
  }
  &-3 {
    font-size: $heading-3;
  }
  &-4 {
    font-size: $heading-4;
  }
  &-5 {
    font-size: $heading-5;
  }
  &-6 {
    font-size: $heading-6;
  }
}

.page-heading {
  @include media-s {
    padding-top: 2rem;
    font-size: $heading-3;
  }
}
