.logo {
  background-image: url(/assets/img/svg/Herb_Polski.svg);
  background-size: contain;
  background-repeat: no-repeat;
  color: $color-black;
  text-decoration: none;
  padding-left: 2.75rem;
  font-size: $font-s;
  height: 2.5rem;
  line-height: 1.1;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}
