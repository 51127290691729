*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

a {
  &:link,
  &:visited {
    text-decoration: none;
  }

  &:hover {
    text-decoration: underline;
  }
}

ul {
  list-style: none;
}

#app-top,
#app-header,
#app-content,
#app-footer,
.main {
  outline: none !important;

  &:focus {
    outline: none;
  }
}


.main {
  min-height: 37rem;

  @include media-l {
    min-height: auto;
  }
}

abbr {
  text-decoration: none;
}