.container-layout {
  margin: 0 auto;
  max-width: 1320px;
  padding: $mobile-padding;
}

.container-content {
  margin: 0 auto;
  max-width: 1100px;
  padding: $mobile-padding;
}
