.link {
  position: relative;
  display: inline-block;
  cursor: pointer;
  color: $color-primary;
  font-size: $font-s;
  font-weight: 500;

  &::after {
    @include enity-arrow-right;
  }

  &--l {
    font-size: $font-m;
  }

  &--xl {
    font-size: $font-default;
  }

  &--plain {
    margin-right: 0;

    &::after {
      display: none;
    }
  }

  &--back {
    &::after {
      display: none;
    }

    &::before {
      @include enity-arrow-right;
      transform: rotate(-90deg);
    }
  }

  &--btn,
  &--btn-primary {
    border-radius: 2rem;
    padding: 1rem 2rem;
    z-index: 1;
  }

  &--btn {
    background-color: $color-white;
    border: 1px solid $color-primary;
  }

  &--btn-primary {
    color: $color-white;
    background-color: $color-primary;
  }
}
