@font-face {
  font-family: gov-icons;
  src: url(../../assets/fonts/gov-icons.eot);
  src: url(../../assets/fonts/gov-icons.eot) format('embedded-opentype'),
    url(../../assets/fonts/gov-icons.woff) format('woff'),
    url(../../assets/fonts/gov-icons.ttf);
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

.gov-icon {
  font-family: gov-icons;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.gov-icon--account:before {
  content: '';
}

.gov-icon--arrow-left-thin:before {
  content: '';
}

.gov-icon--arrow-right-thin:before {
  content: '';
}

.gov-icon--business:before {
  content: '';
}

.gov-icon--home:before {
  content: '';
}

.gov-icon--citizen:before {
  content: '';
}

.gov-icon--external-big:before {
  content: '';
}

.gov-icon--external:before {
  content: '';
}

.gov-icon--official:before {
  content: '';
}

.gov-icon--arrow-right-2-center:before {
  content: '';
}

.gov-icon--arrow-left:before {
  content: '';
}

.gov-icon--arrow-right:before {
  content: '';
}

.gov-icon--arrow-down:before {
  content: '';
}

.gov-icon--arrow-up:before {
  content: '';
}

.gov-icon--file:before {
  content: '';
}

.gov-icon--calendar:before {
  content: '';
}

.gov-icon--map-marker:before {
  content: '';
}

.gov-icon--search:before {
  content: '';
}

.gov-icon--context-help:before {
  content: '';
}

.gov-icon--success:before {
  content: '';
}

.gov-icon--info:before {
  content: '';
}

.gov-icon--alert:before {
  content: '';
}

.gov-icon--close:before {
  content: 'x';
}

.gov-icon--facebook:before {
  content: '';
}

.gov-icon--twitter:before {
  content: '';
}

.gov-icon--instagram:before {
  content: '';
}

.gov-icon--youtube:before {
  content: '';
}

.gov-icon--linkedin:before {
  content: '';
}

.gov-icon--flickr:before {
  content: '';
}

.gov-icon--license-by:before {
  content: '';
}

.gov-icon--license-cc:before {
  content: '';
}

.gov-icon--midicine:before {
  content: '';
}

.gov-icon--traffic:before {
  content: '';
}

.gov-icon--water:before {
  content: '';
}

.gov-icon--weather:before {
  content: '';
}

.gov-icon--warning-other:before {
  content: '';
}

.gov-icon--trend-rising:before {
  content: '';
}

.gov-icon--trend-declining:before {
  content: '';
}

.gov-icon--trend-no-change:before {
  content: '';
}

a[target='_blank']:after {
  display: inline-block;
  font-family: gov-icons;
  content: '';
  font-size: 1.4rem;
  vertical-align: top;
  line-height: 1rem;
}

.hint i:before {
  font-family: gov-icons;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: '';
  font-size: 1.1rem;
}
