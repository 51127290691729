.social-media {
  &__list {
    display: flex;
    align-items: center;
  }

  &__item {
    &:not(:last-child) {
      margin-right: 2.5rem;

      @include media-xs {
        margin-right: 1.25rem;
      }
    }
  }

  &__link {
    &:link,
    &:visited {
      color: inherit;
    }

    // .gov-icon reset
    &[target='_blank'] {
      &::after {
        content: none;
      }
    }
  }

  &__image {
    vertical-align: middle;
  }
}
