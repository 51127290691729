@import '/src/scss/abstracts/variables';
@import '/src/scss/abstracts/mixins';

.cdk-dialog-container {
  background: $color-white;
  padding: 1.5rem;
  box-shadow: 4px 8px 28px 0px rgba(0, 0, 0, 0.04);
  opacity: 1;
  width: 100%;
  max-height: 80%;
  max-width: 1080px;
  color: $color-black;

  @include media-m {
    padding: 1rem;
  }
}

.cdk-global-overlay-wrapper {
  padding: 1.5rem;
  
  @include media-m {
    padding: 0.75rem;
  }
}

.cdk-overlay-backdrop {
  background-color: rgba($color-gray-dark-3, 0.66);
}

.cdk-overlay-pane {
  overflow: auto;
}

.dialog {
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.dialog-lg {
  width: 1080px;

  .cdk-dialog-container {
    display: flex;
    height: auto;
  }
}

.dialog-header {
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  line-height: 1.25;
  font-size: 2rem;

  @include media-m {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
}

.dialog-close-button {
  cursor: pointer;
  font-family: inherit;
  background-color: transparent;
  border: none;
  border-radius: none;
  outline: none;
  margin-left: 0.75rem;
}

.dialog-close-icon {
  display: block;
  width: 2rem;
  height: 2rem;
  background-image: url(/assets/img/svg/close-primary.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.dialog-content {
  overflow: auto;
  flex: 1;
  max-width: 100%;
}