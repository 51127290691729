@import '/src/scss/abstracts/variables.scss';
@import '/src/scss/abstracts/mixins.scss';

.http-error {
  text-align: center;
  padding-top: 20rem;
  padding-bottom: 6rem;
  background-repeat: no-repeat;
  background-position: 50% 2rem;;

  &--404 {
    background-image: url(/assets/img/svg/404.svg);
  }

  &--500 {
    background-image: url(/assets/img/svg/500.svg);
  }

  &__description {
    line-height: 2;
    padding-bottom: 2rem;

    &-part {
      display: block;

      @include media-xs {
        display: inline;
      }
    }
  }

  @include media-s {
    padding-top: 14rem;
    padding-bottom: 5rem;
    background-size: 20rem;
  }
}