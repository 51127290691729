.image {
  &-description {
      margin-top: 0.25rem;
      font-size: 0.75rem;
      word-wrap: break-word;

      &-bold {
        font-weight: 600;
      }
  }
}