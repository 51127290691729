/** colors **/

$color-primary: #d30d32;
$color-white: #fff;
$color-black: #1a1a1a;

$color-gray-light-1: #fcfcfc;
$color-gray-light-2: #fafafa;
$color-gray-light-3: #dbdbdb;
$color-gray-light-4: #f5f5f5;
$color-gray-light-5: #919191;
$color-gray-light-6: #737373;
$color-gray-light-7: #ccc;
$color-gray-light-8: #ebebeb;

$color-gray-dark-1: #585858;
$color-gray-dark-2: #666;
$color-gray-dark-3: #333;
$color-gray-dark-4: #4d4d4d;
$color-gray-dark-5: #4c4c4c;

$color-invalid: #ff0000;
$color-email: #204d83;

/** breakpoints **/

$breakpoint-xl: 1440px;
$breakpoint-l: 1280px;
$breakpoint-m: 1076px;
$breakpoint-s: 900px;
$breakpoint-xs: 700px;
$breakpoint-xxs: 576px;

/** content width **/

$content-xl: 1296px;
$content-l: 1076px;
$content-m: 856px;

/** font size **/

$font-s: 0.75rem; // 12px
$font-m: 0.875rem; // 14px
$font-default: 1rem; // 16px
$font-l: 1.125rem; // 18px
$font-xl: 1.25rem; // 20px
$font-xxl: 1.5rem; // 24px

$heading-1: 3rem; // 48px
$heading-2: 2.5rem; // 40px
$heading-3: 2rem; // 32px
$heading-4: 1.5rem; // 24px
$heading-5: 1.25rem; // 20px
$heading-6: 1.125rem; // 18px

/** other **/

$mobile-padding: 0 1rem;
$box-shadow: 4px 8px 28px 0px rgba(0, 0, 0, 0.04);
$search-input-fixed-width: 53.75rem;
