.btn {
  border: 1px solid $color-gray-light-3;
  padding: 0.625rem 0.75rem;
  border-radius: 0.5rem;
  font-size: $font-m;
  position: relative;
  cursor: pointer;
  font-family: inherit;

  &--animate {
    transition: all 0.1s;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 5px 10px rgba($color-black, 0.25);
    }

    &:active {
      transform: translateY(-1px);
      box-shadow: 0 2px 4px rgba($color-black, 0.25);
    }
  }

  &--plain {
    border: none;
    background-color: transparent;
  }

  &--inverse {
    color: $color-gray-dark-2;
    background-color: $color-white;
  }

  &--primary {
    color: $color-white;
    background-color: $color-primary;
    border: none;
  }

  &--chevron {
    &::after {
      content: '\23F7';
      padding-left: 1rem;
    }
  }
}
