.form-control {
  border: 1px solid $color-gray-light-3;
  padding: 0.5rem;
  border-radius: 0.5rem;
  width: auto;
  font-family: inherit;
  font-size: inherit;
  color: $color-gray-dark-2;

  &--extended {
    width: 100%;
  }
}
