@import 'src/scss/abstracts/variables';
@import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";

.cluster-marker {
  .number {
    font-size: 14px;
    color: $color-white;
    z-index: 1;
    position: absolute;
    width: 100%;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    left: -8px;
  }

  .circle,
  .circle::before,
  .circle::after {
    background: $color-primary;
    border-radius: 50%;
    position: absolute;
  }

  .circle {
    &::before,
    &::after {
      content: '';
      opacity: 50%;
    }

    &::after {
      top: -4px;
      left: -4px;
    }

    &::before {
      top: -8px;
      left: -8px;
    }
  }

  &.big {
    .number {
      top: 10px;
    }

    .circle {
      height: 40px;
      width: 40px;

      &::after {
        height: 48px;
        width: 48px;
      }

      &::before {
        height: 56px;
        width: 56px;
      }
    }
  }

  &.md {
    .number {
      top: 6px;
    }

    .circle {
      height: 32px;
      width: 32px;

      &::after {
        height: 40px;
        width: 40px;
      }

      &::before {
        height: 48px;
        width: 48px;
      }
    }
  }

  &.sm {
    .number {
      top: 2px;
    }

    .circle {
      height: 24px;
      width: 24px;

      &::after {
        height: 32px;
        width: 32px;
      }

      &::before {
        height: 40px;
        width: 40px;
      }
    }
  }
}

.leaflet-tile-loaded {
  filter: grayscale(1) !important;
}

.leaflet-popup {
  bottom: -1.0625rem !important;
  font-weight: 400;
  line-height: 1.4;

  @include media-min-s {
    left: 0 !important;
  }

  @include media-s {
    bottom: -0.5rem !important;
  }

  .leaflet-popup-content-wrapper {
    border-radius: 0.5rem;
    box-shadow: none;
    border-bottom-left-radius: 0.125rem;
    border: 1px solid $color-gray-light-7;
    max-width: 16.25rem;
    padding: 0;

    @include media-s {
      border-bottom-left-radius: 0.5rem;
    }

    .leaflet-popup-content {
      font-family: 'Poppins', sans-serif;
      padding: 0.75rem;
      margin: 0;
    }

    .popup-name {
      color: $color-black;
      font-weight: 500;
      line-height: 150%;
      font-size: $font-m;
      margin-top: 0.0625rem;
      margin-bottom: 0;
    }

    .popup-type {
      margin-top: 0.375rem;
      font-size: $font-s;
      margin-bottom: 0;
    }

    .popup-button {
      font-family: inherit;
      color: $color-primary;
      background: none;
      font-size: 0.75rem;
      line-height: 1rem;
      font-weight: 500;
      border: none;
      cursor: pointer;
      position: relative;
      margin-top: 0.5rem;
      display: inline-block;

      &::after {
        @include enity-arrow-right;
        margin-left: 0.25rem;
      }
    }
  }

  .leaflet-popup-tip-container {
    display: none;
  }
}

.map-container {
  .leaflet-control-container {
    .leaflet-control-zoom {
      margin-top: 2rem;
      margin-left: 4.5rem;
      border: none;

      @include media-s {
        display: none;
      }

      .leaflet-control-zoom-in,
      .leaflet-control-zoom-out {
        border-radius: 0.375rem;
        width: 2rem;
        height: 2rem;
        background-image: url(/assets/img/plus.png);

        span {
          display: none;
        }
      }

      .leaflet-control-zoom-in {
        background-image: url(/assets/img/plus.png);
      }

      .leaflet-control-zoom-out {
        background-image: url(/assets/img/minus.png);
      }

      .leaflet-control-zoom-in {
        margin-bottom: 0.5rem;
      }
    }
  }

  &.leaflet-gesture-handling-touch-warning {
    &::after {
      // element will stay hidden for half of the animation time
      animation: reveal 0.8s linear;
    }
  }
}


@keyframes reveal {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  51% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
