.d-block {
  display: block;
}

.d-none {
  display: none;
}

.block-desktop {
  display: block;

  @include media-s {
    display: inline;
  }
}

.grayscale {
  filter: grayscale(100%);
}

.m-0 {
  margin: 0;
}

.rounded {
  border-radius: 0.5rem;
}

.nowrap {
  white-space: nowrap;
}