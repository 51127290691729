.top-menu {
  position: relative;

  &__toggle {
    display: none;
    font-size: $font-s;
    color: $color-black;
    font-weight: 500;
    cursor: pointer;

    &-icon {
      position: relative;
      vertical-align: middle;
      margin-right: 0.5rem;

      &,
      &::before,
      &::after {
        width: 1rem;
        height: 2px;
        display: inline-block;
        background-color: $color-primary;
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        left: 0;
        transition: all 0.25s;
      }
      &::before {
        top: -0.35rem;
      }
      &::after {
        top: 0.3rem;
      }
    }

    &-input:focus + & {
      outline: 2px solid $color-black;
      border-radius: 2px;
    }

    &-close {
      display: none;
    }

    &-input {
      display: none;

      @include media-m {
        display: inline-block;
      }
    }

    &-input:checked ~ & &-title {
      display: none;
    }

    &-input:checked ~ & &-close {
      display: inline-block;
    }

    &-input:checked + & &-icon {
      background-color: transparent;
    }

    &-input:checked + & &-icon::before,
    &-input:checked + & &-icon::after {
      width: 1.25rem;
      top: 0;
    }

    &-input:checked + & &-icon::before {
      transform: rotate(135deg);
    }

    &-input:checked + & &-icon::after {
      transform: rotate(-135deg);
    }

    @include media-m {
      display: inline-block;

      &-input:checked ~ .top-menu__wrapper {
        display: block;
        position: fixed;
        left: 0;
        top: 4.5rem;
        background-color: $color-gray-light-1;
        height: calc(100% - 4.5rem);
        height: calc(var(--height, 100%) - 4.5rem);
        width: 100%;
        z-index: 10000;
        padding: 0 1rem;
        overflow: auto;
      }
    }
  }

  &__heading {
    padding: 2rem 0 1rem 0;
    color: $color-black;
    font-weight: 500;
    display: none;

    @include media-m {
      display: block;
    }
  }

  &__wrapper {
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      background: linear-gradient(
        $color-gray-light-4 0,
        $color-gray-light-1 2rem
      );
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }

    @include media-m {
      display: none;
    }
  }

  &__list {
    display: flex;
    align-items: center;

    @include media-m {
      flex-wrap: wrap;
    }
  }

  &__item {
    color: $color-black;
    font-size: $font-m;
    font-weight: 500;
    position: relative;
    z-index: 1;

    &:not(:first-child) {
      margin-left: 2.5rem;

      @include media-m {
        width: 100%;
        margin-left: 0;
      }
    }

    &.active {
      color: $color-primary;

      .top-menu__link,
      .sub-menu__toggle .sub-menu__toggle-text {
        position: relative;

        &::after {
          content: '';
          border-bottom: 2px solid $color-primary;
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          bottom: -1.75rem;

          @include media-m {
            bottom: -0.5rem;
          }
        }
      }
    }

    @include media-m {
      padding: 1rem 0;
      font-size: $font-l;
    }
  }

  &__link {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__item.active {
    @include media-m {
      &::after {
        bottom: auto;
        top: 3rem;
      }
    }
  }

  &__item.active &__link:hover {
    text-decoration: none;
  }
}

.sub-menu {
  &__toggle {
    border: none;
    background-color: transparent;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
    padding-right: 1rem;

    &-icon {
      transition: 0.25s all;
      display: inline-flex;
      transform: rotate(90deg);
      font-size: 1.25rem;
      vertical-align: middle;
      position: absolute;
      top: 0;

      @include media-m {
        top: 1rem;
        right: 0;
        font-size: 2rem;
      }
    }

    @include media-m {
      padding-right: 0;
    }
  }

  &__list {
    background-color: $color-white;
    border: 1px solid $color-gray-light-3;
    box-shadow: $box-shadow;
    border-radius: 0.5rem;
    padding: 0.25rem 1rem;

    position: absolute;
    z-index: 1;
    right: -0.25rem;
    width: 18rem;

    top: 50%;
    visibility: hidden;
    opacity: 0;
    transition: all 0.25s;
    max-height: 0;

    @include media-m {
      position: relative;
      border: none;
      background-color: transparent;
      box-shadow: none;
      padding: 0 0 0 1.75rem;
      width: inherit;
    }
  }

  &__item {
    padding: 0.5rem 0;

    @include media-m {
      padding: 0.75rem 0;

      &:first-child {
        padding-top: 1.25rem;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  &__link {
    color: $color-black;
    font-size: $font-m;
    display: inline-block;

    @include media-m {
      font-size: $font-l;
    }
  }

  &--visible &__toggle {
    color: $color-primary;

    &-icon {
      transform: rotate(270deg);
      color: $color-primary;
    }
  }

  &--visible &__list {
    top: 150%;
    visibility: visible;
    opacity: 1;
    max-height: 100rem;
  }
}
