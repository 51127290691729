.labelled-group {
  margin-top: 1.25rem;
  font-size: $font-s;
  font-weight: 500;
  color: $color-gray-light-6;
  max-width: 100%;

  &__value {
    display: block;
    font-weight: 400;
    font-size: $font-m;
    color: $color-gray-dark-4;
    padding-top: 0.25rem;
    word-wrap: break-word;
  }

  &--l {
    font-size: $font-m;

    &-value {
      color: $color-gray-dark-3;
    }
  }

  &--first {
    margin-top: 0;
  }
}
