.linked {
  text-align: center;

  &__description {
    padding: 0.5rem 0 2rem 0;

    &-part {
      display: block;

      @include media-s {
        display: inline;
      }
    }

    &--alt {
      font-size: $font-m;
      line-height: 2;
    }

    @include media-s {
      padding: 0rem 0 1.5rem 0;
    }
  }

  &--bg-1,
  &--bg-2 {
    background-color: $color-gray-light-2;
    border-radius: 1rem;
    position: relative;
    overflow: hidden;

    &::before,
    &::after {
      content: '';
      position: absolute;
      height: 175px;
      width: 150px;
      background-repeat: no-repeat;
      background-size: contain;
    }

    &::before {
      right: 0;
      top: 0;
    }

    &::after {
      left: 0;
    }
  }

  &--bg-1 {
    padding: 10rem 0;

    &::before,
    &::after {
      @include media-s {
        width: 100px;
      }
    }

    &::before {
      background-image: url(/assets/img/svg/home-marker-top.svg);
    }

    &::after {
      top: 75%;
      background-image: url(/assets/img/svg/home-marker-bottom.svg);
    }

    @include media-s {
      padding: 6rem 0;
    }
  }

  &--bg-2 {
    padding: 7rem 0 5rem 0;
    margin-bottom: 4rem;

    &::before {
      background-image: url(/assets/img/svg/photos.svg);

      @include media-s {
        width: 125px;
      }
    }

    &::after {
      bottom: 0;
      background-image: url(/assets/img/svg/database.svg);

      @include media-s {
        height: 125px;
      }
    }

    @include media-s {
      padding: 4.5rem 0;
    }
  }

  &__heading {
    position: relative;
    z-index: 1;

    @include media-s {
      font-size: $heading-4;
    }

    &-part {
      display: block;

      @include media-s {
        display: inline;
      }
    }
  }
}
