/** media queries **/

@mixin media-xl {
  @media only screen and (min-width: $breakpoint-xl) {
    @content;
  }
}

@mixin media-l {
  @media only screen and (max-width: $breakpoint-l) {
    @content;
  }
}

@mixin media-m {
  @media only screen and (max-width: $breakpoint-m) {
    @content;
  }
}

@mixin media-s {
  @media only screen and (max-width: $breakpoint-s) {
    @content;
  }
}

@mixin media-min-s {
  @media only screen and (min-width: $breakpoint-s) {
    @content;
  }
}

@mixin media-xs {
  @media only screen and (max-width: $breakpoint-xs) {
    @content;
  }
}

@mixin media-xxs {
  @media only screen and (max-width: $breakpoint-xxs) {
    @content;
  }
}

@mixin media-landscape {
  @media only screen and (orientation: landscape) {
    @content;
  }
}
//** media queries **/

/** icons - html entity **/

@mixin enity-arrow-right {
  content: '\e923';
  display: inline-block;
  font-family: gov-icons;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: rotate(90deg);
}

//** icons **/

@mixin sr-only {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

@mixin visibility-animated {
  transition: all .25s;
  max-height: 0;
  opacity: 0;
  visibility: hidden;

  &.visibility-animated {
    margin-top: 1.5rem;
    height: auto;
    max-height: 40rem;
    opacity: 1;
    visibility: visible;
  }
}

@mixin dropdown-sort-label {
  font-size: $font-m;
  font-weight: 500;
  color: $color-gray-dark-3;
  margin-right: 0.5rem;
}