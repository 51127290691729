@charset "UTF-8";
@import '@angular/cdk/overlay-prebuilt.css'; /** colors **/
/** breakpoints **/
/** content width **/
/** font size **/
/** other **/
/** media queries **/
/** icons - html entity **/
@import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

a:link, a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

ul {
  list-style: none;
}

#app-top,
#app-header,
#app-content,
#app-footer,
.main {
  outline: none !important;
}
#app-top:focus,
#app-header:focus,
#app-content:focus,
#app-footer:focus,
.main:focus {
  outline: none;
}

.main {
  min-height: 37rem;
}
@media only screen and (max-width: 1280px) {
  .main {
    min-height: auto;
  }
}

abbr {
  text-decoration: none;
}

body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  line-height: 1.4;
  color: #666;
  box-sizing: border-box;
  position: relative;
  background: linear-gradient(#fff 0 4.5rem, #f5f5f5 4.5rem, #fcfcfc 6rem, #fcfcfc 6rem 100%);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  color: #1a1a1a;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.heading-1 {
  font-size: 3rem;
}
.heading-2 {
  font-size: 2.5rem;
}
.heading-3 {
  font-size: 2rem;
}
.heading-4 {
  font-size: 1.5rem;
}
.heading-5 {
  font-size: 1.25rem;
}
.heading-6 {
  font-size: 1.125rem;
}

@media only screen and (max-width: 900px) {
  .page-heading {
    padding-top: 2rem;
    font-size: 2rem;
  }
}

.sr-only {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

.d-block {
  display: block;
}

.d-none {
  display: none;
}

.block-desktop {
  display: block;
}
@media only screen and (max-width: 900px) {
  .block-desktop {
    display: inline;
  }
}

.grayscale {
  filter: grayscale(100%);
}

.m-0 {
  margin: 0;
}

.rounded {
  border-radius: 0.5rem;
}

.nowrap {
  white-space: nowrap;
}

.logo {
  background-image: url(/assets/img/svg/Herb_Polski.svg);
  background-size: contain;
  background-repeat: no-repeat;
  color: #1a1a1a;
  text-decoration: none;
  padding-left: 2.75rem;
  font-size: 0.75rem;
  height: 2.5rem;
  line-height: 1.1;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}

@font-face {
  font-family: gov-icons;
  src: url(../../assets/fonts/gov-icons.eot);
  src: url(../../assets/fonts/gov-icons.eot) format("embedded-opentype"), url(../../assets/fonts/gov-icons.woff) format("woff"), url(../../assets/fonts/gov-icons.ttf);
  font-weight: 400;
  font-style: normal;
  font-display: block;
}
.gov-icon {
  font-family: gov-icons;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.gov-icon--account:before {
  content: "\e900";
}

.gov-icon--arrow-left-thin:before {
  content: "\e911";
}

.gov-icon--arrow-right-thin:before {
  content: "\e912";
}

.gov-icon--business:before {
  content: "\e904";
}

.gov-icon--home:before {
  content: "\e905";
}

.gov-icon--citizen:before {
  content: "\e906";
}

.gov-icon--external-big:before {
  content: "\e907";
}

.gov-icon--external:before {
  content: "\e908";
}

.gov-icon--official:before {
  content: "\e909";
}

.gov-icon--arrow-right-2-center:before {
  content: "\ea10";
}

.gov-icon--arrow-left:before {
  content: "\e90e";
}

.gov-icon--arrow-right:before {
  content: "\e90d";
}

.gov-icon--arrow-down:before {
  content: "\ea23";
}

.gov-icon--arrow-up:before {
  content: "\e90b";
}

.gov-icon--file:before {
  content: "\e901";
}

.gov-icon--calendar:before {
  content: "\e902";
}

.gov-icon--map-marker:before {
  content: "\e91b";
}

.gov-icon--search:before {
  content: "\e903";
}

.gov-icon--context-help:before {
  content: "\e90a";
}

.gov-icon--success:before {
  content: "\e90c";
}

.gov-icon--info:before {
  content: "\e90f";
}

.gov-icon--alert:before {
  content: "\e910";
}

.gov-icon--close:before {
  content: "x";
}

.gov-icon--facebook:before {
  content: "\e913";
}

.gov-icon--twitter:before {
  content: "\e914";
}

.gov-icon--instagram:before {
  content: "\e915";
}

.gov-icon--youtube:before {
  content: "\e916";
}

.gov-icon--linkedin:before {
  content: "\e91a";
}

.gov-icon--flickr:before {
  content: "\e917";
}

.gov-icon--license-by:before {
  content: "\e918";
}

.gov-icon--license-cc:before {
  content: "\e919";
}

.gov-icon--midicine:before {
  content: "\e91c";
}

.gov-icon--traffic:before {
  content: "\e91d";
}

.gov-icon--water:before {
  content: "\e91e";
}

.gov-icon--weather:before {
  content: "\e91f";
}

.gov-icon--warning-other:before {
  content: "\e920";
}

.gov-icon--trend-rising:before {
  content: "\e923";
}

.gov-icon--trend-declining:before {
  content: "\e922";
}

.gov-icon--trend-no-change:before {
  content: "\e921";
}

a[target=_blank]:after {
  display: inline-block;
  font-family: gov-icons;
  content: "\e908";
  font-size: 1.4rem;
  vertical-align: top;
  line-height: 1rem;
}

.hint i:before {
  font-family: gov-icons;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e90f";
  font-size: 1.1rem;
}

.container-layout {
  margin: 0 auto;
  max-width: 1320px;
  padding: 0 1rem;
}

.container-content {
  margin: 0 auto;
  max-width: 1100px;
  padding: 0 1rem;
}

.top-menu {
  position: relative;
}
.top-menu__toggle {
  display: none;
  font-size: 0.75rem;
  color: #1a1a1a;
  font-weight: 500;
  cursor: pointer;
}
.top-menu__toggle-icon {
  position: relative;
  vertical-align: middle;
  margin-right: 0.5rem;
}
.top-menu__toggle-icon, .top-menu__toggle-icon::before, .top-menu__toggle-icon::after {
  width: 1rem;
  height: 2px;
  display: inline-block;
  background-color: #d30d32;
}
.top-menu__toggle-icon::before, .top-menu__toggle-icon::after {
  content: "";
  position: absolute;
  left: 0;
  transition: all 0.25s;
}
.top-menu__toggle-icon::before {
  top: -0.35rem;
}
.top-menu__toggle-icon::after {
  top: 0.3rem;
}
.top-menu__toggle-input:focus + .top-menu__toggle {
  outline: 2px solid #1a1a1a;
  border-radius: 2px;
}
.top-menu__toggle-close {
  display: none;
}
.top-menu__toggle-input {
  display: none;
}
@media only screen and (max-width: 1076px) {
  .top-menu__toggle-input {
    display: inline-block;
  }
}
.top-menu__toggle-input:checked ~ .top-menu__toggle .top-menu__toggle-title {
  display: none;
}
.top-menu__toggle-input:checked ~ .top-menu__toggle .top-menu__toggle-close {
  display: inline-block;
}
.top-menu__toggle-input:checked + .top-menu__toggle .top-menu__toggle-icon {
  background-color: transparent;
}
.top-menu__toggle-input:checked + .top-menu__toggle .top-menu__toggle-icon::before, .top-menu__toggle-input:checked + .top-menu__toggle .top-menu__toggle-icon::after {
  width: 1.25rem;
  top: 0;
}
.top-menu__toggle-input:checked + .top-menu__toggle .top-menu__toggle-icon::before {
  transform: rotate(135deg);
}
.top-menu__toggle-input:checked + .top-menu__toggle .top-menu__toggle-icon::after {
  transform: rotate(-135deg);
}
@media only screen and (max-width: 1076px) {
  .top-menu__toggle {
    display: inline-block;
  }
  .top-menu__toggle-input:checked ~ .top-menu__wrapper {
    display: block;
    position: fixed;
    left: 0;
    top: 4.5rem;
    background-color: #fcfcfc;
    height: calc(100% - 4.5rem);
    height: calc(var(--height, 100%) - 4.5rem);
    width: 100%;
    z-index: 10000;
    padding: 0 1rem;
    overflow: auto;
  }
}
.top-menu__heading {
  padding: 2rem 0 1rem 0;
  color: #1a1a1a;
  font-weight: 500;
  display: none;
}
@media only screen and (max-width: 1076px) {
  .top-menu__heading {
    display: block;
  }
}
.top-menu__wrapper::before {
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(#f5f5f5 0, #fcfcfc 2rem);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
@media only screen and (max-width: 1076px) {
  .top-menu__wrapper {
    display: none;
  }
}
.top-menu__list {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1076px) {
  .top-menu__list {
    flex-wrap: wrap;
  }
}
.top-menu__item {
  color: #1a1a1a;
  font-size: 0.875rem;
  font-weight: 500;
  position: relative;
  z-index: 1;
}
.top-menu__item:not(:first-child) {
  margin-left: 2.5rem;
}
@media only screen and (max-width: 1076px) {
  .top-menu__item:not(:first-child) {
    width: 100%;
    margin-left: 0;
  }
}
.top-menu__item.active {
  color: #d30d32;
}
.top-menu__item.active .top-menu__link,
.top-menu__item.active .sub-menu__toggle .sub-menu__toggle-text {
  position: relative;
}
.top-menu__item.active .top-menu__link::after,
.top-menu__item.active .sub-menu__toggle .sub-menu__toggle-text::after {
  content: "";
  border-bottom: 2px solid #d30d32;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1.75rem;
}
@media only screen and (max-width: 1076px) {
  .top-menu__item.active .top-menu__link::after,
  .top-menu__item.active .sub-menu__toggle .sub-menu__toggle-text::after {
    bottom: -0.5rem;
  }
}
@media only screen and (max-width: 1076px) {
  .top-menu__item {
    padding: 1rem 0;
    font-size: 1.125rem;
  }
}
.top-menu__link {
  color: inherit;
  text-decoration: none;
}
.top-menu__link:hover {
  text-decoration: underline;
}
@media only screen and (max-width: 1076px) {
  .top-menu__item.active::after {
    bottom: auto;
    top: 3rem;
  }
}
.top-menu__item.active .top-menu__link:hover {
  text-decoration: none;
}

.sub-menu__toggle {
  border: none;
  background-color: transparent;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  cursor: pointer;
  padding-right: 1rem;
}
.sub-menu__toggle-icon {
  transition: 0.25s all;
  display: inline-flex;
  transform: rotate(90deg);
  font-size: 1.25rem;
  vertical-align: middle;
  position: absolute;
  top: 0;
}
@media only screen and (max-width: 1076px) {
  .sub-menu__toggle-icon {
    top: 1rem;
    right: 0;
    font-size: 2rem;
  }
}
@media only screen and (max-width: 1076px) {
  .sub-menu__toggle {
    padding-right: 0;
  }
}
.sub-menu__list {
  background-color: #fff;
  border: 1px solid #dbdbdb;
  box-shadow: 4px 8px 28px 0px rgba(0, 0, 0, 0.04);
  border-radius: 0.5rem;
  padding: 0.25rem 1rem;
  position: absolute;
  z-index: 1;
  right: -0.25rem;
  width: 18rem;
  top: 50%;
  visibility: hidden;
  opacity: 0;
  transition: all 0.25s;
  max-height: 0;
}
@media only screen and (max-width: 1076px) {
  .sub-menu__list {
    position: relative;
    border: none;
    background-color: transparent;
    box-shadow: none;
    padding: 0 0 0 1.75rem;
    width: inherit;
  }
}
.sub-menu__item {
  padding: 0.5rem 0;
}
@media only screen and (max-width: 1076px) {
  .sub-menu__item {
    padding: 0.75rem 0;
  }
  .sub-menu__item:first-child {
    padding-top: 1.25rem;
  }
  .sub-menu__item:last-child {
    padding-bottom: 0;
  }
}
.sub-menu__link {
  color: #1a1a1a;
  font-size: 0.875rem;
  display: inline-block;
}
@media only screen and (max-width: 1076px) {
  .sub-menu__link {
    font-size: 1.125rem;
  }
}
.sub-menu--visible .sub-menu__toggle {
  color: #d30d32;
}
.sub-menu--visible .sub-menu__toggle-icon {
  transform: rotate(270deg);
  color: #d30d32;
}
.sub-menu--visible .sub-menu__list {
  top: 150%;
  visibility: visible;
  opacity: 1;
  max-height: 100rem;
}

.link {
  position: relative;
  display: inline-block;
  cursor: pointer;
  color: #d30d32;
  font-size: 0.75rem;
  font-weight: 500;
}
.link::after {
  content: "\e923";
  display: inline-block;
  font-family: gov-icons;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: rotate(90deg);
}
.link--l {
  font-size: 0.875rem;
}
.link--xl {
  font-size: 1rem;
}
.link--plain {
  margin-right: 0;
}
.link--plain::after {
  display: none;
}
.link--back::after {
  display: none;
}
.link--back::before {
  content: "\e923";
  display: inline-block;
  font-family: gov-icons;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: rotate(90deg);
  transform: rotate(-90deg);
}
.link--btn, .link--btn-primary {
  border-radius: 2rem;
  padding: 1rem 2rem;
  z-index: 1;
}
.link--btn {
  background-color: #fff;
  border: 1px solid #d30d32;
}
.link--btn-primary {
  color: #fff;
  background-color: #d30d32;
}

.btn {
  border: 1px solid #dbdbdb;
  padding: 0.625rem 0.75rem;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  position: relative;
  cursor: pointer;
  font-family: inherit;
}
.btn--animate {
  transition: all 0.1s;
}
.btn--animate:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 10px rgba(26, 26, 26, 0.25);
}
.btn--animate:active {
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(26, 26, 26, 0.25);
}
.btn--plain {
  border: none;
  background-color: transparent;
}
.btn--inverse {
  color: #666;
  background-color: #fff;
}
.btn--primary {
  color: #fff;
  background-color: #d30d32;
  border: none;
}
.btn--chevron::after {
  content: "⏷";
  padding-left: 1rem;
}

.form-control {
  border: 1px solid #dbdbdb;
  padding: 0.5rem;
  border-radius: 0.5rem;
  width: auto;
  font-family: inherit;
  font-size: inherit;
  color: #666;
}
.form-control--extended {
  width: 100%;
}

.bm-checkbox {
  color: #1a1a1a;
  font-size: 0.875rem;
}

.image-description {
  margin-top: 0.25rem;
  font-size: 0.75rem;
  word-wrap: break-word;
}
.image-description-bold {
  font-weight: 600;
}

/** colors **/
/** breakpoints **/
/** content width **/
/** font size **/
/** other **/
.cluster-marker .number {
  font-size: 14px;
  color: #fff;
  z-index: 1;
  position: absolute;
  width: 100%;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  left: -8px;
}
.cluster-marker .circle,
.cluster-marker .circle::before,
.cluster-marker .circle::after {
  background: #d30d32;
  border-radius: 50%;
  position: absolute;
}
.cluster-marker .circle::before, .cluster-marker .circle::after {
  content: "";
  opacity: 50%;
}
.cluster-marker .circle::after {
  top: -4px;
  left: -4px;
}
.cluster-marker .circle::before {
  top: -8px;
  left: -8px;
}
.cluster-marker.big .number {
  top: 10px;
}
.cluster-marker.big .circle {
  height: 40px;
  width: 40px;
}
.cluster-marker.big .circle::after {
  height: 48px;
  width: 48px;
}
.cluster-marker.big .circle::before {
  height: 56px;
  width: 56px;
}
.cluster-marker.md .number {
  top: 6px;
}
.cluster-marker.md .circle {
  height: 32px;
  width: 32px;
}
.cluster-marker.md .circle::after {
  height: 40px;
  width: 40px;
}
.cluster-marker.md .circle::before {
  height: 48px;
  width: 48px;
}
.cluster-marker.sm .number {
  top: 2px;
}
.cluster-marker.sm .circle {
  height: 24px;
  width: 24px;
}
.cluster-marker.sm .circle::after {
  height: 32px;
  width: 32px;
}
.cluster-marker.sm .circle::before {
  height: 40px;
  width: 40px;
}

.leaflet-tile-loaded {
  filter: grayscale(1) !important;
}

.leaflet-popup {
  bottom: -1.0625rem !important;
  font-weight: 400;
  line-height: 1.4;
}
@media only screen and (min-width: 900px) {
  .leaflet-popup {
    left: 0 !important;
  }
}
@media only screen and (max-width: 900px) {
  .leaflet-popup {
    bottom: -0.5rem !important;
  }
}
.leaflet-popup .leaflet-popup-content-wrapper {
  border-radius: 0.5rem;
  box-shadow: none;
  border-bottom-left-radius: 0.125rem;
  border: 1px solid #ccc;
  max-width: 16.25rem;
  padding: 0;
}
@media only screen and (max-width: 900px) {
  .leaflet-popup .leaflet-popup-content-wrapper {
    border-bottom-left-radius: 0.5rem;
  }
}
.leaflet-popup .leaflet-popup-content-wrapper .leaflet-popup-content {
  font-family: "Poppins", sans-serif;
  padding: 0.75rem;
  margin: 0;
}
.leaflet-popup .leaflet-popup-content-wrapper .popup-name {
  color: #1a1a1a;
  font-weight: 500;
  line-height: 150%;
  font-size: 0.875rem;
  margin-top: 0.0625rem;
  margin-bottom: 0;
}
.leaflet-popup .leaflet-popup-content-wrapper .popup-type {
  margin-top: 0.375rem;
  font-size: 0.75rem;
  margin-bottom: 0;
}
.leaflet-popup .leaflet-popup-content-wrapper .popup-button {
  font-family: inherit;
  color: #d30d32;
  background: none;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 500;
  border: none;
  cursor: pointer;
  position: relative;
  margin-top: 0.5rem;
  display: inline-block;
}
.leaflet-popup .leaflet-popup-content-wrapper .popup-button::after {
  content: "\e923";
  display: inline-block;
  font-family: gov-icons;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: rotate(90deg);
  margin-left: 0.25rem;
}
.leaflet-popup .leaflet-popup-tip-container {
  display: none;
}

.map-container .leaflet-control-container .leaflet-control-zoom {
  margin-top: 2rem;
  margin-left: 4.5rem;
  border: none;
}
@media only screen and (max-width: 900px) {
  .map-container .leaflet-control-container .leaflet-control-zoom {
    display: none;
  }
}
.map-container .leaflet-control-container .leaflet-control-zoom .leaflet-control-zoom-in,
.map-container .leaflet-control-container .leaflet-control-zoom .leaflet-control-zoom-out {
  border-radius: 0.375rem;
  width: 2rem;
  height: 2rem;
  background-image: url(/assets/img/plus.png);
}
.map-container .leaflet-control-container .leaflet-control-zoom .leaflet-control-zoom-in span,
.map-container .leaflet-control-container .leaflet-control-zoom .leaflet-control-zoom-out span {
  display: none;
}
.map-container .leaflet-control-container .leaflet-control-zoom .leaflet-control-zoom-in {
  background-image: url(/assets/img/plus.png);
}
.map-container .leaflet-control-container .leaflet-control-zoom .leaflet-control-zoom-out {
  background-image: url(/assets/img/minus.png);
}
.map-container .leaflet-control-container .leaflet-control-zoom .leaflet-control-zoom-in {
  margin-bottom: 0.5rem;
}
.map-container.leaflet-gesture-handling-touch-warning::after {
  animation: reveal 0.8s linear;
}

@keyframes reveal {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  51% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
.linked {
  text-align: center;
}
.linked__description {
  padding: 0.5rem 0 2rem 0;
}
.linked__description-part {
  display: block;
}
@media only screen and (max-width: 900px) {
  .linked__description-part {
    display: inline;
  }
}
.linked__description--alt {
  font-size: 0.875rem;
  line-height: 2;
}
@media only screen and (max-width: 900px) {
  .linked__description {
    padding: 0rem 0 1.5rem 0;
  }
}
.linked--bg-1, .linked--bg-2 {
  background-color: #fafafa;
  border-radius: 1rem;
  position: relative;
  overflow: hidden;
}
.linked--bg-1::before, .linked--bg-1::after, .linked--bg-2::before, .linked--bg-2::after {
  content: "";
  position: absolute;
  height: 175px;
  width: 150px;
  background-repeat: no-repeat;
  background-size: contain;
}
.linked--bg-1::before, .linked--bg-2::before {
  right: 0;
  top: 0;
}
.linked--bg-1::after, .linked--bg-2::after {
  left: 0;
}
.linked--bg-1 {
  padding: 10rem 0;
}
@media only screen and (max-width: 900px) {
  .linked--bg-1::before, .linked--bg-1::after {
    width: 100px;
  }
}
.linked--bg-1::before {
  background-image: url(/assets/img/svg/home-marker-top.svg);
}
.linked--bg-1::after {
  top: 75%;
  background-image: url(/assets/img/svg/home-marker-bottom.svg);
}
@media only screen and (max-width: 900px) {
  .linked--bg-1 {
    padding: 6rem 0;
  }
}
.linked--bg-2 {
  padding: 7rem 0 5rem 0;
  margin-bottom: 4rem;
}
.linked--bg-2::before {
  background-image: url(/assets/img/svg/photos.svg);
}
@media only screen and (max-width: 900px) {
  .linked--bg-2::before {
    width: 125px;
  }
}
.linked--bg-2::after {
  bottom: 0;
  background-image: url(/assets/img/svg/database.svg);
}
@media only screen and (max-width: 900px) {
  .linked--bg-2::after {
    height: 125px;
  }
}
@media only screen and (max-width: 900px) {
  .linked--bg-2 {
    padding: 4.5rem 0;
  }
}
.linked__heading {
  position: relative;
  z-index: 1;
}
@media only screen and (max-width: 900px) {
  .linked__heading {
    font-size: 1.5rem;
  }
}
.linked__heading-part {
  display: block;
}
@media only screen and (max-width: 900px) {
  .linked__heading-part {
    display: inline;
  }
}

.labelled-group {
  margin-top: 1.25rem;
  font-size: 0.75rem;
  font-weight: 500;
  color: #737373;
  max-width: 100%;
}
.labelled-group__value {
  display: block;
  font-weight: 400;
  font-size: 0.875rem;
  color: #4d4d4d;
  padding-top: 0.25rem;
  word-wrap: break-word;
}
.labelled-group--l {
  font-size: 0.875rem;
}
.labelled-group--l-value {
  color: #333;
}
.labelled-group--first {
  margin-top: 0;
}

.social-media__list {
  display: flex;
  align-items: center;
}
.social-media__item:not(:last-child) {
  margin-right: 2.5rem;
}
@media only screen and (max-width: 700px) {
  .social-media__item:not(:last-child) {
    margin-right: 1.25rem;
  }
}
.social-media__link:link, .social-media__link:visited {
  color: inherit;
}
.social-media__link[target=_blank]::after {
  content: none;
}
.social-media__image {
  vertical-align: middle;
}

/** colors **/
/** breakpoints **/
/** content width **/
/** font size **/
/** other **/
/** media queries **/
/** icons - html entity **/
.http-error {
  text-align: center;
  padding-top: 20rem;
  padding-bottom: 6rem;
  background-repeat: no-repeat;
  background-position: 50% 2rem;
}
.http-error--404 {
  background-image: url(/assets/img/svg/404.svg);
}
.http-error--500 {
  background-image: url(/assets/img/svg/500.svg);
}
.http-error__description {
  line-height: 2;
  padding-bottom: 2rem;
}
.http-error__description-part {
  display: block;
}
@media only screen and (max-width: 700px) {
  .http-error__description-part {
    display: inline;
  }
}
@media only screen and (max-width: 900px) {
  .http-error {
    padding-top: 14rem;
    padding-bottom: 5rem;
    background-size: 20rem;
  }
}

/** colors **/
/** breakpoints **/
/** content width **/
/** font size **/
/** other **/
/** media queries **/
/** icons - html entity **/
.cdk-dialog-container {
  background: #fff;
  padding: 1.5rem;
  box-shadow: 4px 8px 28px 0px rgba(0, 0, 0, 0.04);
  opacity: 1;
  width: 100%;
  max-height: 80%;
  max-width: 1080px;
  color: #1a1a1a;
}
@media only screen and (max-width: 1076px) {
  .cdk-dialog-container {
    padding: 1rem;
  }
}

.cdk-global-overlay-wrapper {
  padding: 1.5rem;
}
@media only screen and (max-width: 1076px) {
  .cdk-global-overlay-wrapper {
    padding: 0.75rem;
  }
}

.cdk-overlay-backdrop {
  background-color: rgba(51, 51, 51, 0.66);
}

.cdk-overlay-pane {
  overflow: auto;
}

.dialog {
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.dialog-lg {
  width: 1080px;
}
.dialog-lg .cdk-dialog-container {
  display: flex;
  height: auto;
}

.dialog-header {
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  line-height: 1.25;
  font-size: 2rem;
}
@media only screen and (max-width: 1076px) {
  .dialog-header {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
}

.dialog-close-button {
  cursor: pointer;
  font-family: inherit;
  background-color: transparent;
  border: none;
  border-radius: none;
  outline: none;
  margin-left: 0.75rem;
}

.dialog-close-icon {
  display: block;
  width: 2rem;
  height: 2rem;
  background-image: url(/assets/img/svg/close-primary.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.dialog-content {
  overflow: auto;
  flex: 1;
  max-width: 100%;
}